import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next";

class Footer extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className={'footer'}>
                &#169;&#160;
                {t('copyright')} Max Erler 2022
                &#160;&#160;
                <Link to={'/imprint'} target={'_blank'} rel={'nofollow'}>{t('imprint')}</Link>
                &#160;&#160;
                <Link to={'/privacy'} target={'_blank'} rel={'nofollow'}>{t('privacy')}</Link>
            </div>
        );
    }
}

export default withTranslation()(Footer);