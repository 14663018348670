import React, {Component} from 'react';
import SkillBar from "./skillBar";

export default class Skills extends Component {
    render() {

        const skills = [
            {type: "WordPress", level: 95},
            {type: "HTML", level: 95},
            {type: "CSS / SCSS", level: 95},
            {type: "Java", level: 75},
            {type: "Python", level: 70},
            {type: "JavaScript", level: 60},
            {type: "React", level: 50},
            {type: "C#", level: 40},
            {type: "Azure", level: 30},
            {type: "AWS", level: 30},
        ];

        return (
            <div>
                <SkillBar hue="225" saturation="100" skills={skills}/>
            </div>
        );
    }
}